<template>
  <section class="TableSocios">
    <div class="socios" v-if="socios">
      <h3 class="mt-10 ml-2">
        SOCIOS
      </h3>
      <el-table ref="table" :data="socios">
        <el-table-column label="" width="30px">
          <template slot-scope="scope">
            <el-button
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
              v-if="scope.row.Partners.length > 0"
              type="text"
              @click="toogleExpand(scope.row)"
              ><i class="onpoint-caret-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Nome">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.Person.Name }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="CPF/CNPJ" width="200px">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.Person.CpfCnpj | maskCpfCnpj }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Participação" width="120px">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.PercentageParticipation }}%
            </p>
          </template>
        </el-table-column>
        <el-table-column label="PEP">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{
                scope.row.IsPep
                  ? "Sim" +
                    (scope.row.PEPReason
                      ? " - Motivo: " + scope.row.PEPReason
                      : " - Motivo não encontrado")
                  : "Não"
              }}
            </p>
          </template>
        </el-table-column>

        <el-table-column type="expand" width="1" style="    padding: 0px 15px;">
          <template slot-scope="props">
            Relacionados:
            <el-table :data="props.row.Partners" border style="width: 100%">
              <el-table-column label="Nome">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{ scope.row.Person.Name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="CPF/CNPJ">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{ scope.row.Person.CpfCnpj | maskCpfCnpj }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="PEP">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{
                      scope.row.IsPep
                        ? "Sim" +
                          (scope.row.PEPReason
                            ? " - Motivo: " + scope.row.PEPReason
                            : " - Motivo não encontrado")
                        : "Não"
                    }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="administrador" v-if="administradores">
      <h3 class="mt-10 ml-2">
        ADMINISTRADORES
      </h3>
      <el-table ref="table" :data="administradores">
        <el-table-column label="" width="30px">
          <template slot-scope="scope">
            <el-button
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
              v-if="scope.row.Partners.length > 0"
              type="text"
              @click="toogleExpand(scope.row)"
              ><i class="onpoint-caret-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Nome">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.Person.Name }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="CPF/CNPJ" width="200px">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.Person.CpfCnpj | maskCpfCnpj }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="PEP">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{
                scope.row.IsPep
                  ? "Sim" +
                    (scope.row.PEPReason
                      ? " - Motivo: " + scope.row.PEPReason
                      : " - Motivo não encontrado")
                  : "Não"
              }}
            </p>
          </template>
        </el-table-column>

        <el-table-column type="expand" width="1" style="    padding: 0px 15px;">
          <template slot-scope="props">
            Relacionados:
            <el-table :data="props.row.Partners" border style="width: 100%">
              <el-table-column label="Nome">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{ scope.row.Person.Name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="CPF/CNPJ">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{ scope.row.Person.CpfCnpj | maskCpfCnpj }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="PEP">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{
                      scope.row.IsPep
                        ? "Sim" +
                          (scope.row.PEPReason
                            ? " - Motivo: " + scope.row.PEPReason
                            : " - Motivo não encontrado")
                        : "Não"
                    }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="representantes" v-if="representantes">
      <h3 class="mt-10 ml-2">
        REPRESENTANTES
      </h3>
      <el-table ref="table" :data="representantes">
        <el-table-column label="" width="30px">
          <template slot-scope="scope">
            <el-button
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
              v-if="scope.row.Partners.length > 0"
              type="text"
              @click="toogleExpand(scope.row)"
              ><i class="onpoint-caret-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Nome">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.Person.Name }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="CPF/CNPJ" width="200px">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{ scope.row.Person.CpfCnpj | maskCpfCnpj }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="PEP">
          <template slot-scope="scope">
            <p
              :style="
                scope.row.IsPep ? 'color: rgba(var(--vs-success),1)' : null
              "
            >
              {{
                scope.row.IsPep
                  ? "Sim" +
                    (scope.row.PEPReason
                      ? " - Motivo: " + scope.row.PEPReason
                      : " - Motivo não encontrado")
                  : "Não"
              }}
            </p>
          </template>
        </el-table-column>

        <el-table-column type="expand" width="1" style="    padding: 0px 15px;">
          <template slot-scope="props">
            Relacionados:
            <el-table :data="props.row.Partners" border style="width: 100%">
              <el-table-column label="Nome">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{ scope.row.Person.Name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="CPF/CNPJ">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{ scope.row.Person.CpfCnpj | maskCpfCnpj }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="PEP">
                <template slot-scope="scope">
                  <p
                    :style="
                      scope.row.IsPep
                        ? 'color: rgba(var(--vs-success),1)'
                        : null
                    "
                  >
                    {{
                      scope.row.IsPep
                        ? "Sim" +
                          (scope.row.PEPReason
                            ? " - Motivo: " + scope.row.PEPReason
                            : " - Motivo não encontrado")
                        : "Não"
                    }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-if="!socios && !administradores && !representantes">
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
        <i class="onpoint-file-text"></i>
        <p>Quadro de sócios não cadastrado</p>
      </div>
    </template>
  </section>
</template>
<script>
import axiosInstance from "@/axios";
export default {
  name: "socios",
  props: {
    Socios: {},
    TypeText: { type: String }
  },
  data() {
    return {
      person: {},
      representantes: false,
      administradores: false,
      socios: false
    };
  },
  mounted() {
    if (Array.isArray(this.Socios)) {
      if (this.Socios[0].length > 0) this.administradores = this.Socios[0];
      if (this.Socios[1].length > 0) this.socios = this.Socios[1];
      if (this.Socios[2].length > 0) this.representantes = this.Socios[2];
    } else {
      this.getInfo();
    }
  },
  methods: {
    toogleExpand(row) {
      let $table = this.$refs.table;
      this.$refs.table.toggleRowExpansion(row);
    },
    getInfo() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/person/GetPersonQSAsByRootPersonIdAndPEPPartnersByRole?RootPersonUniqueId=${this.Socios}`
          )
          .then(response => {
            this.person = response.data.Response;
            if (response.data.Response[0].length > 0)
              this.administradores = response.data.Response[0];
            if (response.data.Response[1].length > 0)
              this.socios = response.data.Response[1];
            if (response.data.Response[2].length > 0)
              this.representantes = response.data.Response[2];
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    TypeCardString(value) {
      switch (value) {
        case "CORRETORA_PEP":
          return " - Corretora";
        case "TOMADOR_QSA_PEP":
          return " - Tomador";
        case "SEGURADO_QSA_PEP":
          return " - Segurado";
        case "PROPOSTA":
          return " - Proposta";
        case undefined:
          return " ";
      }
    },
    printRole(roleName) {
      switch (roleName) {
        case "SOCIOS":
          return "Sócios";
        case "REPRESENTANTE":
          return "Representantes";
        case "ADMINISTRACAO":
          return "Administrativo";
        case "PEP_RELACIONADO":
          return "PEPs relacionados";
      }
    }
  }
};
</script>

<style>
.TableSocios h3 {
  color: rgba(var(--vs-primary), 1) !important;
  margin-bottom: 25px;
  margin-left: 15px !important;
}
.TableSocios .vs-con-table table {
  border-collapse: collapse !important;
}
.TableSocios .vs-con-table .vs-con-tbody {
  border: 0px solid #f8f8f8;
}
.TableSocios .vs-table--tbody-table .tr-values {
  border-top: 1px solid #e2e2e2 !important;
}

.TableSocios .vs-con-table td:first-child {
  border-left: none !important;
}
.TableSocios .table-list td {
  border-top: solid 1px #e2e2e2;
  padding: 25px !important;
  padding-left: 15px !important;
}

.kaban .CardSocios {
  -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.kaban .TableSocios h3 {
  margin-left: 25px !important;
  margin-top: 20px !important;
}
.kaban .CardSocios .table-list .vs-table-text {
  -webkit-box-pack: start;
  -ms-flex-pack: center;
  justify-content: left;
}
.onpoint-file-text::before{  
  font-size: 56px;
}
</style>
