var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "TableSocios" },
    [
      _vm.socios
        ? _c(
            "div",
            { staticClass: "socios" },
            [
              _c("h3", { staticClass: "mt-10 ml-2" }, [
                _vm._v("\n      SOCIOS\n    "),
              ]),
              _c(
                "el-table",
                { ref: "table", attrs: { data: _vm.socios } },
                [
                  _c("el-table-column", {
                    attrs: { label: "", width: "30px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.Partners.length > 0
                                ? _c(
                                    "el-button",
                                    {
                                      style: scope.row.IsPep
                                        ? "color: rgba(var(--vs-success),1)"
                                        : null,
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toogleExpand(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "onpoint-caret-right",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2219714758
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Nome" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.Person.Name) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3580349891
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "CPF/CNPJ", width: "200px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("maskCpfCnpj")(
                                          scope.row.Person.CpfCnpj
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      929516649
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Participação", width: "120px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.PercentageParticipation
                                      ) +
                                      "%\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2082343067
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "PEP" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.IsPep
                                          ? "Sim" +
                                              (scope.row.PEPReason
                                                ? " - Motivo: " +
                                                  scope.row.PEPReason
                                                : " - Motivo não encontrado")
                                          : "Não"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1719323139
                    ),
                  }),
                  _c("el-table-column", {
                    staticStyle: { padding: "0px 15px" },
                    attrs: { type: "expand", width: "1" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v("\n          Relacionados:\n          "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: props.row.Partners,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "Nome" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.Person.Name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "CPF/CNPJ" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm._f("maskCpfCnpj")(
                                                          scope.row.Person
                                                            .CpfCnpj
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "PEP" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.IsPep
                                                          ? "Sim" +
                                                              (scope.row
                                                                .PEPReason
                                                                ? " - Motivo: " +
                                                                  scope.row
                                                                    .PEPReason
                                                                : " - Motivo não encontrado")
                                                          : "Não"
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1216070374
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.administradores
        ? _c(
            "div",
            { staticClass: "administrador" },
            [
              _c("h3", { staticClass: "mt-10 ml-2" }, [
                _vm._v("\n      ADMINISTRADORES\n    "),
              ]),
              _c(
                "el-table",
                { ref: "table", attrs: { data: _vm.administradores } },
                [
                  _c("el-table-column", {
                    attrs: { label: "", width: "30px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.Partners.length > 0
                                ? _c(
                                    "el-button",
                                    {
                                      style: scope.row.IsPep
                                        ? "color: rgba(var(--vs-success),1)"
                                        : null,
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toogleExpand(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "onpoint-caret-right",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2219714758
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Nome" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.Person.Name) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3580349891
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "CPF/CNPJ", width: "200px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("maskCpfCnpj")(
                                          scope.row.Person.CpfCnpj
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      929516649
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "PEP" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.IsPep
                                          ? "Sim" +
                                              (scope.row.PEPReason
                                                ? " - Motivo: " +
                                                  scope.row.PEPReason
                                                : " - Motivo não encontrado")
                                          : "Não"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1719323139
                    ),
                  }),
                  _c("el-table-column", {
                    staticStyle: { padding: "0px 15px" },
                    attrs: { type: "expand", width: "1" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v("\n          Relacionados:\n          "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: props.row.Partners,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "Nome" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.Person.Name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "CPF/CNPJ" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm._f("maskCpfCnpj")(
                                                          scope.row.Person
                                                            .CpfCnpj
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "PEP" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.IsPep
                                                          ? "Sim" +
                                                              (scope.row
                                                                .PEPReason
                                                                ? " - Motivo: " +
                                                                  scope.row
                                                                    .PEPReason
                                                                : " - Motivo não encontrado")
                                                          : "Não"
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1216070374
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.representantes
        ? _c(
            "div",
            { staticClass: "representantes" },
            [
              _c("h3", { staticClass: "mt-10 ml-2" }, [
                _vm._v("\n      REPRESENTANTES\n    "),
              ]),
              _c(
                "el-table",
                { ref: "table", attrs: { data: _vm.representantes } },
                [
                  _c("el-table-column", {
                    attrs: { label: "", width: "30px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.Partners.length > 0
                                ? _c(
                                    "el-button",
                                    {
                                      style: scope.row.IsPep
                                        ? "color: rgba(var(--vs-success),1)"
                                        : null,
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toogleExpand(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "onpoint-caret-right",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2219714758
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Nome" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.Person.Name) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3580349891
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "CPF/CNPJ", width: "200px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("maskCpfCnpj")(
                                          scope.row.Person.CpfCnpj
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      929516649
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "PEP" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "p",
                                {
                                  style: scope.row.IsPep
                                    ? "color: rgba(var(--vs-success),1)"
                                    : null,
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.IsPep
                                          ? "Sim" +
                                              (scope.row.PEPReason
                                                ? " - Motivo: " +
                                                  scope.row.PEPReason
                                                : " - Motivo não encontrado")
                                          : "Não"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1719323139
                    ),
                  }),
                  _c("el-table-column", {
                    staticStyle: { padding: "0px 15px" },
                    attrs: { type: "expand", width: "1" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v("\n          Relacionados:\n          "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: props.row.Partners,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "Nome" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.Person.Name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "CPF/CNPJ" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm._f("maskCpfCnpj")(
                                                          scope.row.Person
                                                            .CpfCnpj
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "PEP" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  style: scope.row.IsPep
                                                    ? "color: rgba(var(--vs-success),1)"
                                                    : null,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.IsPep
                                                          ? "Sim" +
                                                              (scope.row
                                                                .PEPReason
                                                                ? " - Motivo: " +
                                                                  scope.row
                                                                    .PEPReason
                                                                : " - Motivo não encontrado")
                                                          : "Não"
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1216070374
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.socios && !_vm.administradores && !_vm.representantes
        ? [_vm._m(0)]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32",
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", [_vm._v("Quadro de sócios não cadastrado")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }