import { render, staticRenderFns } from "./Table-Socios.vue?vue&type=template&id=a32861d4"
import script from "./Table-Socios.vue?vue&type=script&lang=js"
export * from "./Table-Socios.vue?vue&type=script&lang=js"
import style0 from "./Table-Socios.vue?vue&type=style&index=0&id=a32861d4&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a32861d4')) {
      api.createRecord('a32861d4', component.options)
    } else {
      api.reload('a32861d4', component.options)
    }
    module.hot.accept("./Table-Socios.vue?vue&type=template&id=a32861d4", function () {
      api.rerender('a32861d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/vs-table/Table-Socios.vue"
export default component.exports